import {
    Table,
} from "semantic-ui-react";
import React, { useEffect, useState } from "react";


function OfficialHistoryTableRow({ current_row }) {

    return (
        <Table.Row>
            <Table.Cell>{current_row.date}</Table.Cell>
            <Table.Cell>{current_row.os_version}</Table.Cell>
            <Table.Cell>{current_row.mcu_version}</Table.Cell>
            <Table.Cell>{current_row.sea_version}</Table.Cell>
            <Table.Cell>{current_row.gea_version}</Table.Cell>
            <Table.Cell>{current_row.tag}</Table.Cell>
        </Table.Row>
    )
}

export default OfficialHistoryTableRow