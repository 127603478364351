import {
    Table,
    Button,
    Menu,
    Container,
    Input,
} from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { iteratee } from "lodash";

const menuStyle = {
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  marginBottom: "1em",
  marginTop: "0em",
  transition: "box-shadow 0.5s ease, padding 0.5s ease",
};

function FeishuTaskRow({ docId }) {
    const cancel_watch = React.useCallback(async () => {
        await axios.get((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/stop_monitor_feishu_doc" + "?doc_id=" + docId).then(
            (res) => {
                console.log(res.data)
            }
        )
    }
    )

    return (
        <div>
            <Table.Row>
                <Table.Cell>{docId}</Table.Cell>
                <Table.Cell>
                    <Button onClick={cancel_watch} content="点击停止查阅该文档"></Button>
                </Table.Cell>
            </Table.Row>
        </div>
    )
}

export default FeishuTaskRow