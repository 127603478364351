import axios from "axios"
import React,{Component} from 'react';
import { Tab } from "semantic-ui-react";

function FileUploader({ setFile, File, setExptProd, ExptProd, userName }) {
    const upload_file = React.useCallback(async () => {
        // Update the formData object

        const formData = new FormData();
        formData.append("file", File)
        formData.append("version", ExptProd)
        formData.append("userName", userName)

        await axios.post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/upload_expt", formData);
      })
    return (<div>
        <h1>上传临终遗言文件</h1>
        <div>
            输入产物包名称:
            <input type="text" onChange={(e)=>{setExptProd(e.target.value)}} />
        </div>
        <div>
            <input type="file" onChange={(e)=>{setFile(e.target.files[0])}}/>
        </div>
        <button onClick={upload_file}>Upload!</button>
    </div>
    )
}

export default FileUploader