import {
    Dropdown,
    Button,
    Input,
    Label,
    Table,
    Tab,
    Popup,
} from "semantic-ui-react";
import axios from "axios";
import React, { useEffect, useState } from "react";


function UserHistoryTableRow({ current_row }) {
    const [mcuVersion, setMcuVersion] = useState([]);
    const [osVersion, setOsVersion] = useState([]);
    const addRemarks = React.useCallback(async () => {
        await axios.post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/add_remarks", current_row).then(
            (res) => {
                console.log(res.data)
            }
        )
    })

    const confirmOfficial = React.useCallback(async () => {
        await axios.post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/confirm_official" + "?mcu_version=" + mcuVersion + "&os_version=" + osVersion, current_row).then(
            (res) => {
                console.log(res.data)
            }
        )
    }
    )

    return (
        <Table.Row>
            <Table.Cell>{current_row.mf_system}</Table.Cell>
            <Table.Cell>{current_row.package_kind}</Table.Cell>
            <Table.Cell>{current_row.project}</Table.Cell>
            <Table.Cell>{current_row.system_yaml}</Table.Cell>
            <Table.Cell>{current_row.production}</Table.Cell>
            <Table.Cell>
                <Input labelPosition="right" placeholder={current_row.remark} size="small">
                    <input onChange={(e) => (current_row.remark = e.target.value)} />
                </Input>
                <Button onClick={addRemarks} label="点击更新remark"/>
            </Table.Cell>
            <Table.Cell>
                <Popup
                    on='click'
                    trigger={
                        <Button color='red' icon='flask' content='confirm official' />
                    }
                    content={
                        <div>
                            <div>在mdc上输入mdc-tool upgrade display version 查看mcu和os的版本</div>
                            <Input placeholder="mcu_version">
                                <input onChange={(e) => (setMcuVersion(e.target.value))}></input>
                            </Input>
                            <Input placeholder="os_version">
                                <input onChange={(e) => (setOsVersion(e.target.value))}></input>
                            </Input>
                            <Button onClick={confirmOfficial} content="提交"></Button>
                        </div>
                    }
                />
            </Table.Cell>
        </Table.Row>
    )
}

export default UserHistoryTableRow