import {
    Dropdown,
    Button,
    Input,
    Label,
    Table,
} from "semantic-ui-react";
import axios from "axios";
import React from "react";


function TableRow({ module, func, repos }) {
    // const header = { "Access-Control-Allow-Origin": "*" };
    // const getRepoBranchs = React.useCallback(
    //     async () => {
    //         let data = {
    //             name: module.name,
    //             git_url: module.git_url,
    //         }
    //         await axios.post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/get_repo_branchs", data, header)
    //             .then((response) => {
    //                 for (let i = 0; i < response.data.data.length; i++) {
    //                     const item = response.data.data[i]
    //                     const line = { key: item, value: item, flag: item, text: item }
    //                     module.branch_list.push(line)
    //                 }
    //             })
    //         console.log(module.branch_list)
    //     },
    // )
    // for (let i = 0; i < repos.length; i++) {
    //     if (repos[i].name === module.name) {
    //         repos[i] = module
    //         break
    //     }
    // }
    // func(repos)
    return (<Table.Row>
        <Table.Cell>
            <a href={module.url} target="_blank">{module.name}</a>
        </Table.Cell>
        <Table.Cell>
            <Input fluid placeholder={module.git_branch} size="small" onChange={(e) => (module.git_branch = e.target.value)
                } />
        </Table.Cell>
        <Table.Cell>
            <Input fluid placeholder={module.git_commit} size="small" onChange={(e) => (module.git_commit = e.target.value)
                } />
        </Table.Cell>
    </Table.Row>)
}

export default TableRow