import { Confirm } from 'semantic-ui-react'
import React, { useEffect, useState } from "react";

function InvaliUserWarning( {user} ) {
        return     (<div>
        <Confirm
        open = {user === "invalid"}
        content = "Invalid user code"
        />
      </div>)
}

function SubmittedTask( { submitCondition, setSubmitCondition } ) {
  const close = React.useCallback(async() => {setSubmitCondition("off")})
  return (
    <div>
      <Confirm 
      content = "已触发CI，看看你的飞书消息"
      open = {submitCondition === "on"}
      onConfirm = {close}
      onCancel = {close}
      />
    </div>
  )
}

export { InvaliUserWarning, SubmittedTask }