import { Confirm } from 'semantic-ui-react'
import React, { useEffect, useState } from "react";

function FinishUpdateBranch( { submitUpdateBranch, setSubmitUpdateBranch, changeInfo, setChangeInfo } ) {
  const close = React.useCallback(async() => {
    setSubmitUpdateBranch("off")
    setChangeInfo("")
  })
  return (
    <div>
      <Confirm 
      content = "已更新mf_system，详情见飞书消息"
      open = {submitUpdateBranch === "on"}
      onConfirm = {close}
      onCancel = {close}
      />
    </div>
  )
}

export default FinishUpdateBranch