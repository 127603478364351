import React, { useEffect, useState } from "react";
import {
  Container,
  Dropdown,
  Header,
  Menu,
  Form,
  Modal,
  Button,
  Input,
  Search,
  Icon,
  Card,
  Table,
  Tab,
  Select,
} from "semantic-ui-react";
import axios from "axios";
import _, { set } from "lodash";
import TableRow from "../dynamic_table/row";
import { InvaliUserWarning, SubmittedTask } from "../popup/invalidUserWarning";
import UserHistoryTableRow from "../dynamic_table/user_history_table";
import OfficialHistoryTableRow from "../dynamic_table/official_build_table";
import FeishuTaskRow from "../dynamic_table/feishu_task";
import FinishUpdateBranch from "../popup/finish_update"
import FileUploader from "../upload/upload";


const token = "ua7txvph24vtkddckjeymuqul23qhf7pfc26cddvubk5fln2u6dq";

const dateRegex = /^\d{4}-\d\d-\d\d-\d\d-\d\d$/;

function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

const initState = {
  loading: false,
  inspectLoading: false,
  results: [],
  value: getStorageValue("branch", "")
};

const menuStyle = {
  border: "none",
  borderRadius: 0,
  boxShadow: "none",
  marginBottom: "1em",
  marginTop: "0em",
  transition: "box-shadow 0.5s ease, padding 0.5s ease",
};



function stateReducer(state, action) {
  switch (action.type) {
    case "CLEAN_QUERY":
      return { ...state, loading: false, value: "" };
    case "START_SEARCH":
      return { ...state, loading: true, value: action.query };
    case "FINISH_SEARCH":
      return { ...state, loading: false, results: action.results };
    case "UPDATE_SELECTION":
      return { ...state, value: action.selection };
    case "FETCHING":
      return { ...state, loading: action.fetching };
    case "INSPECT_START":
      return { ...state, inspectLoading: true };
    case "INSPECT_STOP":
      return { ...state, inspectLoading: false };
    default:
      throw new Error();
  }
}

function pipelineReducer(state, action) {
  switch (action.type) {
    case "ADD_PIPELINE":
      return { ...state, ...action.updates };
    case "REMOVE_PIPELINE":
      const { [action.task_id]: omitted, ...ret } = state;
      return state;
    case "UPDATE_PIPELINE":
      return { ...state, ...action.updates };
    default:
      throw new Error();
  }
}

function UserHistoryPage({ userBuildHistory }) {
  return (
    <div>
      <Container text>
        <Card.Group centered fluid="true" />
      </Container>
      <Container style={{ marginTop: "2em" }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>mf_system</Table.HeaderCell>
              <Table.HeaderCell>package</Table.HeaderCell>
              <Table.HeaderCell>project</Table.HeaderCell>
              <Table.HeaderCell>system_yaml</Table.HeaderCell>
              <Table.HeaderCell>production</Table.HeaderCell>
              <Table.HeaderCell>remark</Table.HeaderCell>
              <Table.HeaderCell>confirm_official</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {userBuildHistory.map((t) => (
              <UserHistoryTableRow current_row={t}
              />
            ))}
          </Table.Body>
        </Table>
      </Container>
    </div>)
}

function OfficialHistoryPage({ officialBuildHistory }) {
  return (
    <div>
      <Container text>
        <Card.Group centered fluid="true" />
      </Container>
      <Container style={{ marginTop: "2em" }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>date</Table.HeaderCell>
              <Table.HeaderCell>os_version</Table.HeaderCell>
              <Table.HeaderCell>mcu_version</Table.HeaderCell>
              <Table.HeaderCell>sea_version</Table.HeaderCell>
              <Table.HeaderCell>gea_version</Table.HeaderCell>
              <Table.HeaderCell>remark</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {officialBuildHistory.map((t) => (
              <OfficialHistoryTableRow current_row={t}
              />
            ))}
          </Table.Body>
        </Table>
      </Container>
    </div>)
}

function FeishuTaskPage({ user, docId, setDocId, feishuTasks }) {
  const add_watch = React.useCallback(async () => {
    await axios.get((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/monitor_feishu_doc?doc_id=" + docId + "&user=" + user).then(
      (res) => {
        console.log(res)
      }
    )
  })
  return (
    <div>
      <Menu borderless style={menuStyle}>
        <Container textAlign='center'>
          <Menu.Item>
            <Input labelPosition="left" placeholder="飞书文档id" size="small">
              <input onChange={(e) => setDocId(e.target.value)} />
            </Input>
          </Menu.Item>
          <Menu.Item>
            <Button
              circular
              color="teal"
              icon="book"
              onClick={add_watch}
            />
          </Menu.Item>
        </Container>
      </Menu>
      <Container text>
        <Card.Group centered fluid="true" />
      </Container>
      <Container style={{ marginTop: "2em" }}>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>doc_id</Table.HeaderCell>
              <Table.HeaderCell>remove</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {feishuTasks.map((t) => (
              <FeishuTaskRow user={user} docId={t} setDocId={setDocId}
              />
            ))}
          </Table.Body>
        </Table>
      </Container>
    </div>)
}

function UploadExptPage({ setFile, File, setExptProd, ExptProd, FileUploader, userName }) {
  return (
    <div>
      <Menu borderless style={menuStyle}>
        <Container textAlign='center'>
          <Menu.Item>
            <FileUploader setFile={setFile} File={File} setExptProd={setExptProd} ExptProd={ExptProd} userName={userName} />
          </Menu.Item>
        </Container>
      </Menu>
    </div>
  )
}

function MainPage({ user, loading, handleSearchChange, results, value, projectOptions, handleInspectRequst, handleUpdateMfSystemRequst, handleClickButton, setRepoInfo, repoInfo, setUser, dispatch, setProjectKind, packageOptions, setPackageKind, CTOptions, setRunCT, inspectLoading, setNewBranchName, setMCUProductionVersion, handleBuildProductionRequest, setGEAProductionVersion, setSEAProductionVersion, mcuProductionVersion }) {
  return (
    <div>
      <Container>
        <Form>
          <Form.Group>
            <Form.Field>
              <label>mf_system分支</label>
              <Search
                loading={loading}
                placeholder="Search mf_system..."
                onResultSelect={(e, data) => {
                  dispatch({
                    type: "UPDATE_SELECTION",
                    selection: data.result.title,
                  });
                }}
                onSearchChange={handleSearchChange}
                results={results}
                value={value}
                fluid
                aligned="right"
                size="small"
              />
            </Form.Field>
            <Form.Field>
              <label>项目</label>
              <Select
                defaultValue="WLINGS"
                options={projectOptions}
                onChange={(e, { value }) => setProjectKind(value)}
              />
            </Form.Field>
            <Form.Field>
              <label>包类型</label>
              <Select
                defaultValue="MF_BOTH"
                options={packageOptions}
                onChange={(e, { value }) => setPackageKind(value)}
              />
            </Form.Field>
            <Form.Field>
              <label>CT</label>
              <Select
                defaultValue="CT_OFF"
                options={CTOptions}
                onChange={(e, { value }) => setRunCT(value)}
              />
            </Form.Field>
            <Form.Field>
              <label>菜单</label>
              <Dropdown text='执行' button>
                <Dropdown.Menu>
                  <Dropdown.Item
                    icon="book"
                    onClick={handleInspectRequst}
                    text="查看分支详情"
                  />
                  <Modal
                    closeIcon
                    trigger={<Dropdown.Item icon='flask' text='修改分支' />}
                    size="mini"
                  >
                    <Header icon='archive' content='修改分支' />
                    <Modal.Content>
                      <Form>
                        <Form.Field>
                          <label>分支名</label>
                          <Input placeholder="新分支名，可以留空" onChange={(e) => (setNewBranchName(e.target.value))} />
                        </Form.Field>
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button positive onClick={handleUpdateMfSystemRequst}>
                        <Icon name='checkmark' /> Update
                      </Button>
                    </Modal.Actions>
                  </Modal>
                  <Dropdown.Item
                    icon="play"
                    onClick={handleClickButton}
                    text="构建Development版本"
                  />
                  <Modal
                    closeIcon
                    trigger={<Dropdown.Item icon='play' text='构建Production版本' />}
                    size="mini"
                  >
                    <Header icon='archive' content='构建Production版本' />
                    <Modal.Content>
                      <Form>
                        <Form.Field>
                          <label>MCU版本</label>
                          <Input placeholder="链接或产物名" onChange={(e) => (setMCUProductionVersion(e.target.value))} value={mcuProductionVersion} />
                        </Form.Field>
                        <Form.Field>
                          <label>GEA版本</label>
                          <Input placeholder="链接或产物名" onChange={(e) => (setGEAProductionVersion(e.target.value))} />
                        </Form.Field>
                        <Form.Field>
                          <label>SEA版本</label>
                          <Input placeholder="链接或产物名" onChange={(e) => (setSEAProductionVersion(e.target.value))} />
                        </Form.Field>
                      </Form>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button onClick={handleBuildProductionRequest} positive>
                        <Icon name="checkmark" /> 触发构建
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Field>
          </Form.Group>
        </Form>
      </Container>
      {repoInfo.length > 0 ? (
        <Container style={{ marginTop: "2em" }}>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>Module</Table.HeaderCell>
                <Table.HeaderCell width={6}>Branch</Table.HeaderCell>
                <Table.HeaderCell width={7}>Commit</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {repoInfo.map((t) => (
                <TableRow
                  key={t.name}
                  module={t}
                  func={setRepoInfo}
                  repos={repoInfo}
                />
              ))}
            </Table.Body>
          </Table>
        </Container>) : null}
    </div>
  );
}

function MainPanesPage() {
  const [selections, setSelections] = useState([]);
  const [state, dispatch] = React.useReducer(stateReducer, initState);
  const { loading, results, value, inspectLoading } = state;
  const timeoutRef = React.useRef();
  const [user, setUser] = useState("");
  const [submitCondition, setSubmitCondition] = useState("off");
  const [packageKind, setPackageKind] = useState("MF_BOTH");
  const [projectKind, setProjectKind] = useState("WLINGS");
  const [runCT, setRunCT] = useState("CT_OFF");

  const [userBuildHistory, setUserBuildHistory] = useState([])
  const [officialBuildHistory, setOfficialBuildHistory] = useState([])
  const [feishuTasks, setFeishuTasks] = useState([])
  const [docId, setDocId] = useState("")
  const [submitUpdateBranch, setSubmitUpdateBranch] = useState("off");
  const [changeInfo, setChangeInfo] = useState("")
  const [newBranchName, setNewBranchName] = useState("")
  const [mcuProductionVersion, setMCUProductionVersion] = useLocalStorage("mcu", "")
  const [geaProductionVersion, setGEAProductionVersion] = useState("")
  const [seaProductionVersion, setSEAProductionVersion] = useState("")

  const [pipelines, setPipelines] = useState({});
  const [File, setFile] = useState(null);
  const [ExptProd, setExptProd] = useState("");

  const packageOptions = [
    { key: 1, text: "MF", value: "MF" },
    { key: 2, text: "MF_BOTH", value: "MF_BOTH" },
    { key: 3, text: "SEA", value: "SEA" },
    { key: 4, text: "DDLD", value: "DDLD" },
    { key: 5, text: "DDLD_BOTH", value: "DDLD_BOTH" },
  ];

  const projectOptions = [
    { key: 1, text: "WLINGS", value: "WLINGS" },
    { key: 2, text: "Refcar", value: "Refcar" },
  ]

  const CTOptions = [
    { key: 1, text: "CT_OFF", value: "CT_OFF" },
    { key: 2, text: "ADC_GEA_CT", value: "ADC_GEA_CT" },
    { key: 3, text: "ADC_EM_CT", value: "ADC_EM_CT" },
  ]

  const [repoInfo, setRepoInfo] = useState([]);

  useEffect(() => {
    // for feishu api
    const query_param = new URLSearchParams(window.location.search);
    if (query_param.get("code") != null) {
      let data = {
        code: query_param.get("code")
      }
      axios.post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/get_user_name", data).then(
        (res) => {
          if (res.data.data.available) {
            setUser(res.data.data.data.name)
          } else {
            console.log("res data not available")
            setUser("invalid")
          }
        }
      )
    }

    clearTimeout(timeoutRef.current);
    dispatch({ type: "FETCHING", fetching: true });

    axios
      .get(
        "https://devops.momenta.works/Momenta/maf/_apis/git/repositories/mf_system/stats/branches?api-version=6.0",
        {
          auth: {
            username: "",
            password: `${token}`,
          },
        }
      )
      .then((res) => {
        const data = res.data.value.flatMap((branch) =>
          branch.commit.author === "np_planning_ci" ||
            branch.commit.author === "cp_planning_ci" ||
            branch.name.includes("_sim_") ||
            branch.name.match(dateRegex)
            ? []
            : [{ title: branch.name, description: branch.commit.commitId }]
        );
        setSelections(data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        dispatch({ type: "FETCHING", fetching: false });
      });
  }, []);

  const handleTabChange = React.useCallback(
    async (e, data) => {
      if (data.activeIndex === 1) {
        await axios.get(
          (process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/get_user_history_product?user_name=" + user).then(
            (res) => {
              setUserBuildHistory(res.data.data)
            }
          )
      } else if (data.activeIndex === 2) {
        await axios.get(
          (process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/get_official_builds"
        ).then(
          (res) => {
            setOfficialBuildHistory(res.data.data)
          }
        )
      } else if (data.activeIndex === 3) {
        await axios.get(
          (process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/GetUserFeishuMonitorTasks?user_name=" + user).then(
            (res) => {
              if (res.data.data) {
                setFeishuTasks(res.data.data)
              }
            }
          )
      }
    }, [user]
  )

  const handleSearchChange = React.useCallback(
    (e, data) => {
      clearTimeout(timeoutRef.current);
      dispatch({ type: "START_SEARCH", query: data.value });

      timeoutRef.current = setTimeout(() => {
        if (data.value.length === 0) {
          dispatch({ type: "CLEAN_QUERY" });
          return;
        }

        const re = new RegExp(data.value, "i");

        dispatch({
          type: "FINISH_SEARCH",
          results: selections.filter((s) => re.test(s.title)),
        });
      }, 300);
    },
    [selections]
  );

  const handleInspectRequst = React.useCallback(async () => {
    let data = {
      branch: value,
      commit: "",
      project: projectKind,
      system_yaml: "",
    };
    if (packageKind === "MF") {
      data.system_yaml = "system.yaml";
    } else if (packageKind === "SEA") {
      data.system_yaml = "system_sea.yaml";
    } else if (packageKind === "LLVM") {
      data.system_yaml = "system_llvm.yaml";
    } else if (packageKind === "MDC") {
      data.system_yaml = "system_mdc.yaml";
    } else if (packageKind === "DDLD") {
      data.system_yaml = "system_ddld.yaml"
    }
    const header = { "Access-Control-Allow-Origin": "*" };
    var repo_list = [];

    dispatch({ "type": "INSPECT_START" });
    const response = await axios
      .post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/view_all_repo", data, header)
      .then((res) => {
        const result = res.data;
        for (const [key, value] of Object.entries(result.data)) {
          repo_list.push({
            name: key,
            url: value.url,
            git_url: value.git_url,
            git_branch: value.git_branch,
            git_commit: value.git_commit,
            branch_list: [],
            source_yaml: value.source_yaml,
            prev_commit: value.prev_commit,
          });
        }
        setRepoInfo(repo_list);
      })
      .catch((error) => {
        console.log(error);
      });

    dispatch({ "type": "INSPECT_STOP" });
  }, [packageKind, user, value, repoInfo, projectKind]);

  const handleUpdateMfSystemRequst = React.useCallback(async () => {
    let data = {
      mf_system: {
        name: "mf_system",
        git_branch: value,
      },
      other_modules: repoInfo,
      user: user,
      branch_name: newBranchName
    }
    console.log("new branch name:" + newBranchName)
    await axios.post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/edit_mf_system", data = data).then((res) => {
      const result = res.data;
      setChangeInfo(result.change_info)
      dispatch({
        type: "UPDATE_SELECTION",
        selection: result.new_branch,
      });
    })
    handleInspectRequst()
    setSubmitUpdateBranch("on")
  }, [packageKind, user, value, projectKind, repoInfo, newBranchName]);


  const handleBuildProductionRequest = React.useCallback(async () => {
    localStorage.setItem("branch", JSON.stringify(value));
    let data = {
      package_info: {
        mcu_version: mcuProductionVersion,
        gea_version: geaProductionVersion,
        sea_version: seaProductionVersion,
      },
      pipeline: {
        package_kind: packageKind,
        create_user: user,
        build_config_dir: projectKind,
        name: "tjcnb_" + packageKind + new Date().toLocaleDateString(),
        branch: value,
        receiver_emails: user + `@momenta.ai`,
        project: projectKind,
        system_yaml_name: "system.yaml",
        scene: runCT,
      },
    }

    const header = { "Access-Control-Allow-Origin": "*" };
    const resposne = await axios
      .post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/build_mdc_production_package", data, header)
      .then((res) => { })
      .catch((error) => {
        console.log(error);
      });
    setSubmitCondition("on")

  }, [mcuProductionVersion, geaProductionVersion, seaProductionVersion, packageKind, user, projectKind, value, runCT]);

  const handleClickButton = React.useCallback(async () => {
    localStorage.setItem("branch", JSON.stringify(value));
    let data = {
      pipeline: {
        package_kind: packageKind,
        create_user: user,
        build_config_dir: projectKind,
        name: "tjcnb_" + packageKind + new Date().toLocaleDateString(),
        branch: value,
        receiver_emails: user + `@momenta.ai`,
        project: projectKind,
        system_yaml_name: "system.yaml",
        scene: runCT,
      },
      version: {
        os_version: "testos",
        mcu_version: "test_mcu",
        sea_version: "test_sea",
        gea_version: "test_gea",
      }
    };

    const header = { "Access-Control-Allow-Origin": "*" };

    const response = await axios
      .post((process.env.NODE_ENV != 'production' ? "http://localhost:7777" : "") + "/api/zzh/tjc/build_package", data, header)
      .then((res) => {
        const task_id = res.data.task_id;
      })
      .catch((error) => {
        console.log(error);
      });
    setSubmitCondition("on")
  }, [packageKind, user, value, projectKind, setSubmitCondition, runCT]);


  const panes = [
    {
      menuItem: '发版',
      render: () => <Tab.Pane >
        <MainPage
          user={user}
          mcuProductionVersion={mcuProductionVersion}
          loading={loading}
          handleSearchChange={handleSearchChange}
          results={results}
          value={value}
          projectOptions={projectOptions}
          handleInspectRequst={handleInspectRequst}
          handleUpdateMfSystemRequst={handleUpdateMfSystemRequst}
          handleClickButton={handleClickButton}
          setRepoInfo={setRepoInfo}
          repoInfo={repoInfo}
          setUser={setUser}
          dispatch={dispatch}
          setProjectKind={setProjectKind}
          packageOptions={packageOptions}
          setPackageKind={setPackageKind}
          CTOptions={CTOptions}
          setRunCT={setRunCT}
          inspectLoading={inspectLoading}
          setNewBranchName={setNewBranchName}
          setMCUProductionVersion={setMCUProductionVersion}
          setGEAProductionVersion={setGEAProductionVersion}
          setSEAProductionVersion={setSEAProductionVersion}
          handleBuildProductionRequest={handleBuildProductionRequest}
        />
      </Tab.Pane>,
    },
    {
      menuItem: '个人',
      render: () => <Tab.Pane><UserHistoryPage userBuildHistory={userBuildHistory} /></Tab.Pane>,
    },
    {
      menuItem: '版本',
      render: () => <Tab.Pane><OfficialHistoryPage officialBuildHistory={officialBuildHistory} /></Tab.Pane>
    },
    {
      menuItem: '飞书',
      render: () => <Tab.Pane><FeishuTaskPage user={user} setDocId={setDocId} feishuTasks={feishuTasks} docId={docId} /></Tab.Pane>
    },
    {
      menuItem: '遗言',
      render: () => <Tab.Pane><UploadExptPage setFile={setFile} File={File} setExptProd={setExptProd} ExptProd={ExptProd} FileUploader={FileUploader} userName={user} /></Tab.Pane>
    },
  ]

  return (
    <div>
      <style>
        {`
          html, body {
            background: #f7f7f7;
          }
        `}
      </style>
      <FinishUpdateBranch
        submitUpdateBranch={submitUpdateBranch}
        setSubmitUpdateBranch={setSubmitUpdateBranch}
        changeInfo={changeInfo}
        setChangeInfo={setChangeInfo}
      />
      <SubmittedTask
        submitCondition={submitCondition}
        setSubmitCondition={setSubmitCondition}
      />
      <InvaliUserWarning user={user} />
      <Container text style={{ marginTop: "2em" }}>
        <Header as="h1">WLS CI</Header>
        <p>This make you trigger ci as simple as drinking water</p>
        <p>Welcome {user}</p>
      </Container>
      <Container>
        <Tab
          panes={panes}
          onTabChange={handleTabChange}
        />
      </Container>
    </div>
  )
}


export default MainPanesPage;
